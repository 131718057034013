<script>
//- TODO: make it work image upload

import model from "@/mixins/model";

export default {
  name: "TaskCheckinsForm",

  mixins: [model],

  methods: {
    removeAnswer(index) {
      if (this.model.answers.length <= 2) {
        return;
      }
      this.model.answers.splice(index, 1);
    },

    addAnswer() {
      this.model.answers.push({
        measurement: "green",
        type: "text",
        content: "",
      });
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="insert_chart_outlined")
      h3.sign__title Nivel Alerta
    article.modal__fields
      .modal__row
        fieldset.modal__field
          label.label * Pregunta:
          ValidationProvider(name="Pregunta", rules="required", v-slot="{ errors }")
            el-input(v-model="model.question")
            span.has-error {{ errors[0] }}


      .modal__row.checkins__answers__title
        fieldset.modal__field
          label.label
            b Respuestas

      .checkins__answers(v-for="(answer, index) in model.answers")
        .modal__row
          fieldset.modal__field
            label.label * Respuesta {{ index + 1 }}
        .modal__row
          fieldset.modal__field
            label.label Tipo de Alerta:
            el-select(v-model="answer.measurement" default-first-option)
              el-option(label="Verde" value="green")
              el-option(label="Amarillo" value="yellow")
              el-option(label="Rojo" value="red")

          fieldset.modal__field
            label.label Formato de Respuesta:
            el-select(v-model="answer.type" default-first-option)
              el-option(label="Texto" value="text")
              //- el-option(label="Imagen" value="image")

        .modal__row
          fieldset.modal__field
            label.label(v-if="answer.type === 'text'") * Texto {{ index + 1 }}:
            ValidationProvider(:name="`Texto ${index +1}`", rules="required", v-slot="{ errors }")
              el-input(type="textarea" v-model="answer.content")
              span.has-error {{ errors[0] }}

            //- label.label(v-if="answer.type === 'image'") Imagen:
              file-upload(
                ref="fileUpload",
                type="image",
                :files="[]", 
                :url="uploadMediaEndpoint",
                method="put",
                param-name="avatar",
                @fileupload-success="uploadFilesSuccess",
                @files-added="filesAdded"
              )

            small(v-show="model.answers.length > 2")
              a(@click="removeAnswer(index)") &times; Eliminar Respuesta {{ index + 1 }}

      .modal__row.checkins__answers__add
        fieldset.modal__field
          a(@click="addAnswer") + Agregar Respuesta
        fieldset.modal__field
</template>

<style lang="scss">
.checkins__answers__title {
  margin-top: 20px;
}

.checkins__answers {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light-gray;
}

.checkins__answers__add {
  margin-bottom: 20px;
}
</style>
