var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal__block"},[_c('div',{staticClass:"modal__section"},[_c('div',{staticClass:"modal__sign sign"},[_c('div',{staticClass:"sign__icon"},[_c('micon',{attrs:{"name":"insert_chart_outlined"}})],1),_c('h3',{staticClass:"sign__title"},[_vm._v("Respuestas personalizadas")])]),_c('article',{staticClass:"modal__fields"},[_vm._l((_vm.model.responses),function(response,i){return _c('div',{staticClass:"responses"},[_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_c('b',[_vm._v("Respuesta "+_vm._s(_vm.getColorName(response.color)))])])]),_c('el-button',{attrs:{"type":"danger","plain":""},on:{"click":function($event){return _vm.removeResponse(i)}}},[_vm._v("×")])],1),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Alerta:")]),_c('ValidationProvider',{attrs:{"name":("Alerta Respuesta " + (_vm.getColorName(response.value))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{attrs:{"placeholder":"Seleccionar nivel Alerta","filterable":"","clearable":"","default-first-option":""},model:{value:(response.color),callback:function ($$v) {_vm.$set(response, "color", $$v)},expression:"response.color"}},_vm._l((_vm.colorNames),function(colorName){return _c('el-option',{key:colorName.value,attrs:{"label":colorName.label,"value":colorName.value,"disabled":_vm.isUsed(colorName.value)}})}),1),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Mensaje:")]),_c('ValidationProvider',{attrs:{"name":("Mensaje Respuesta " + (_vm.getColorName(response.color))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(response.message.text),callback:function ($$v) {_vm.$set(response.message, "text", $$v)},expression:"response.message.text"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("Color mensaje:")]),_c('el-color-picker',{attrs:{"placeholder":"Seleccione un color de texto","type":"color","clearable":"","name":"Color","rows":"1"},model:{value:(response.message.textColor),callback:function ($$v) {_vm.$set(response.message, "textColor", $$v)},expression:"response.message.textColor"}})],1)]),_vm._l((response.media),function(media,mediaIndex){return _c('div',{staticClass:"response-media"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_c('b',[_vm._v("Recomendación "+_vm._s(mediaIndex +1))])])]),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tipo de Recomendación:")]),_c('ValidationProvider',{attrs:{"name":("Tipo Archivo Respuesta " + (_vm.getColorName(response.color))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{on:{"change":function($event){return _vm.resetMediaContent(media)}},model:{value:(media.type),callback:function ($$v) {_vm.$set(media, "type", $$v)},expression:"media.type"}},[_c('el-option',{attrs:{"label":"Imagen","value":"image"}}),_c('el-option',{attrs:{"label":"Audio","value":"audio"}}),_c('el-option',{attrs:{"label":"Video","value":"video"}})],1),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Título:")]),_c('ValidationProvider',{attrs:{"name":("Titulo Archivo Respuesta " + (_vm.getColorName(response.color))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(media.title),callback:function ($$v) {_vm.$set(media, "title", $$v)},expression:"media.title"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("Descripción:")]),_c('el-input',{model:{value:(media.description),callback:function ($$v) {_vm.$set(media, "description", $$v)},expression:"media.description"}})],1)]),(media.type === 'image' || media.type === 'audio')?_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[(media.type === 'image')?_c('label',{staticClass:"label"},[_vm._v("Imagen:")]):_c('label',{staticClass:"label"},[_vm._v("Audio:")]),_c('file-upload',{ref:"fileUpload",refInFor:true,attrs:{"type":media.type,"url":_vm.uploadMediaEndpoint,"files":_vm.getFiles(media),"dropzone-options":_vm.getDropdownOptions()},on:{"file-added":function (file) { return _vm.onFilesAdded(file, media); },"file-removed":function (file) { return _vm.onFileRemoved(file, media); },"files-removed":_vm.onFilesRemoved,"fileupload-success":_vm.onFileUploadSuccess,"fileupload-error":_vm.onMediaError}})],1)]):_vm._e(),(media.type === 'video')?_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Link del Video:")]),_c('ValidationProvider',{attrs:{"name":"Link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},model:{value:(media.content[0].value),callback:function ($$v) {_vm.$set(media.content[0], "value", $$v)},expression:"media.content[0].value"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('small',[_c('a',{staticClass:"remove",on:{"click":function($event){return _vm.removeMedia(response, mediaIndex)}}},[_vm._v("× Eliminar recomendación "+_vm._s(mediaIndex +1))])])])])])}),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field media__add"},[(response.media.length && _vm.showMediaResponseError)?_c('span',{staticClass:"response__error"},[_vm._v("Debes completar la recomendación de la respuesta "+_vm._s(_vm.getColorName(response.color))+" para poder agregar una nueva")]):_vm._e(),_c('a',{staticClass:"media__add",on:{"click":function($event){return _vm.addMedia(response)}}},[_vm._v("+ Agregar recomendación")])])])],2)}),_c('div',{staticClass:"modal__row responses__add"},[_c('fieldset',{staticClass:"modal__field"},[(_vm.model.responses.length && _vm.showResponseError)?_c('span',{staticClass:"response__error"},[_vm._v("Debes completar la respuesta "+_vm._s(_vm.getColorName(_vm.model.responses[_vm.model.responses.length -1].color))+" para poder agregar una nueva")]):_vm._e(),_c('el-button',{on:{"click":_vm.addResponse}},[_vm._v("+ Agregar respuestas personalizadas")])],1)])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }