<script>
import configService from "@/services/config";
import reportRecommendationsService from "@/services/report-recommendations";
import model from "@/mixins/model";
import FileUpload from "@/components/ui/FileUpload";

export default {
  name: "TaskReportsForm",

  mixins: [model],

  components: {
    FileUpload,
  },

  data() {
    return {
      s3Url: configService.s3Url,
      recommendationsIdsToDelete: [],
      hasFiles: false,
    };
  },

  props: {
    isUpdate: {
      type: Boolean,
      required: true,
    },
    formType: {
      type: String,
      required: true,
    },
  },

  methods: {
    newReportRecommendation() {
      const lastRecommendation =
        this.model.recommendations[this.model.recommendations.length - 1];

      if (lastRecommendation && !lastRecommendation.title) {
        return;
      }

      this.model.recommendations.push({
        title: "",
        description: "",
        videoUrl: "",
      });
    },

    removeReportRecommendation(index) {
      if (this.model.recommendations[index]._id) {
        this.recommendationsIdsToDelete.push(
          this.model.recommendations[index]._id
        );
      }
      this.model.recommendations.splice(index, 1);
    },

    filesAdded() {
      this.hasFiles = true;
    },

    setFile(file, index) {
      if (file && this.model.recommendations[index]) {
        this.model.recommendations[index].file = file;
      }
    },

    removeFile(index) {
      this.hasFiles = false;
      if (this.model.recommendations[index]) {
        this.model.recommendations[index].file = null;
      }
    },

    async saveRecommendations(taskID) {
      let modelType = "patient";

      if (this.formType === "config-task") {
        modelType = "default";
      } else if (this.formType === "treatment-task") {
        modelType = "treatment";
      }

      const upsertPromises = this.model.recommendations
        .filter((recommendation) => !recommendation._id)
        .map((recommendation) =>
          reportRecommendationsService.create({
            taskID,
            modelType,
            recommendation,
          })
        );

      const deletePromises = this.recommendationsIdsToDelete.map(
        (recommendationID) =>
          reportRecommendationsService.delete({
            modelType,
            taskID,
            recommendationID,
          })
      );

      await Promise.all([...upsertPromises, ...deletePromises]);
      this.hasFiles = false;
      this.$emit("taskfileupload-success");
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="image")
      h3.sign__title Contenido
    article.modal__fields
      .modal__row
        fieldset.modal__field
          el-checkbox(v-model="model.isEditable")
            b Permitir que el usuario pueda editar el contenido del reporte

      .report__recommendations(v-for="(recommendation, index) in model.recommendations")
        .modal__row
          fieldset.modal__field
            label.label Recomendación {{ index + 1 }}:

        .modal__row
          fieldset.modal__field
            label.label * Título:
            ValidationProvider(:name="`titulo recomendación-${index + 1}`" rules="required", v-slot="{ errors }")
              el-input(v-model="recommendation.title" :disabled="!!recommendation._id")
              span.has-error {{ errors[0] }}

        .modal__row
          fieldset.modal__field
            label.label * Descripción:
            ValidationProvider(:name="`descripcion recomendación-${index + 1}`" rules="required", v-slot="{ errors }")
              el-input(
                type="textarea"
                v-model="recommendation.description"
                :disabled="!!recommendation._id"
                rows="10"
                maxlength="250"
              )
              span.has-error {{ errors[0] }}

        .modal__row
          fieldset.modal__field
            label.label Link del Video (opcional):
            ValidationProvider(:name="`url-${index + 1}`", rules="url", v-slot="{ errors }")
              el-input(:disabled="!!recommendation._id" v-model="recommendation.videoUrl", :class="{'has-error': errors[0]}")
              span.has-error {{ errors[0] }}

        .modal__row(v-if="isUpdate && recommendation.file && recommendation.file.path")
          fieldset.modal__field
            label.label Imagen:
            img.recommendation__image-preview(:src="`${s3Url}/${recommendation.file.path}`")

        .modal__row(v-else="!isUpdate")
          fieldset.modal__field
            label.label Imagen (opcional):
            file-upload(
              url="url"
              :dropzone-options="{ maxFiles: 1 }"
              @file-added="(file) => setFile(file, index)"
              @files-removed="() => removeFile(index)"
            )

        .modal__row
          fieldset.modal__field
            small
              a(@click="removeReportRecommendation(index)") &times; Eliminar Recomendación {{ index + 1 }}
        //- .repport__recommendations__remove
        //-   button.button.button--action(@click="removeReportRecommendation(index)", v-tooltip="'Borrar recomendación'")
        //-     micon(name="delete_forever")


      .modal__row
        fieldset.modal__field.report__recommendations__add
          a(@click="newReportRecommendation") + Agregar Recomendación

    article.modal__fields
</template>

<style lang="scss" scoped>
.report__recommendations {
  padding: 20px 0;
  border-bottom: 1px solid $light-gray;

  .recommendation__image-preview {
    width: 200px;
  }

  .recommendation__image-preview {
    width: 200px;
  }

  &__add {
    margin: 20px 0;
  }

  &__remove {
    display: flex;
    align-items: center;
  }
}
</style>
