<script>
import model from "@/mixins/model";

export default {
  name: "TaskRecommendationsForm",

  mixins: [model],

  props: {
    isUpdate: { type: Boolean, default: false },
  },
};
</script>

<template lang="pug">
div
  .modal__row
    fieldset.modal__field
      label.label * Categoría:
      ValidationProvider(name="Categoría" rules="required" v-slot="{errors}")
        el-select(
          v-model="model.category"
          placeholder="Seleccioná una"
          required
        )
          el-option(label="Nutrición" value="nutrition")
          el-option(label="Hidratación" value="hydration")
          el-option(label="Salud" value="health")
          el-option(label="Actividad física" value="physicalActivity")

        span.has-error {{ errors[0] }}
  .modal__row
    fieldset.modal__field
      el-checkbox(v-model="model.isFeatured" :disabled="isUpdate")
        b Mostrar tarea como destacada
</template>
