<script>
import model from "@/mixins/model";
import FileUpload from "@/components/ui/FileUpload";

export default {
  name: "TaskResponse",

  mixins: [model],

  props: {
    uploadMediaEndpoint: {
      type: String,
      required: true,
    },
    onMediaError: {
      type: Function,
      default: () => {},
    },
  },

  components: {
    FileUpload,
  },

  data() {
    return {
      showResponseError: false,
      showMediaResponseError: false,
      hasFiles: false,

      colorNames: [
        { label: "Roja", value: "red" },
        { label: "Amarilla", value: "yellow" },
        { label: "Verde", value: "green" },
      ],

      files: [],
    };
  },

  methods: {
    isUsed(colorName) {
      return this.model.responses.some(
        (response) => response.color === colorName
      );
    },

    addResponse() {
      this.showResponseError = false;
      const lastResponse =
        this.model.responses[this.model.responses.length - 1];
      if (lastResponse && (!lastResponse.color || !lastResponse.message.text)) {
        this.showResponseError = true;
        return;
      }

      this.model.responses.push({
        color: "",
        message: {
          text: "",
        },
        media: [],
      });
    },

    removeResponse(index) {
      this.showResponseError = false;
      this.model.responses.splice(index, 1);
    },

    getColorName(color) {
      const colorName = this.colorNames.find((elem) => color === elem.value);

      return colorName?.label || "SELECCIONE ALERTA";
    },

    addMedia(response) {
      this.showMediaResponseError = false;
      const lastMediaResponse = response.media[response.media.length - 1];

      if (
        lastMediaResponse &&
        (!lastMediaResponse.title || !lastMediaResponse.type)
      ) {
        this.showMediaResponseError = true;
        return;
      }

      response.media.push({
        title: "",
        type: "",
        content: [],
      });
    },

    removeMedia(response, mediaIndex) {
      this.showMediaResponseError = false;
      response.media.splice(mediaIndex, 1);
    },

    getDropdownOptions() {
      return {
        method: "put",
        paramName: "avatar",
        uploadMultiple: true,
        maxFiles: 3,
        maxFilesize: 5,
      };
    },

    getFiles(media) {
      return media.content.slice();
    },

    onFilesAdded(file, media) {
      media.content.push({ originalFileName: file.name });
      this.hasFiles = true;
    },

    onFileRemoved(file, media) {
      media.content.splice(
        media.content.findIndex(
          (addedFile) => addedFile.originalFileName === file.name
        ),
        1
      );
    },

    onFilesRemoved() {
      this.hasFiles = this.model.responses.some((response) => {
        return response.media.some((media) => media.content.length);
      });
    },

    uploadFiles() {
      if (
        this.$refs.fileUpload &&
        this.$refs.fileUpload.length &&
        this.hasFiles
      ) {
        this.$refs.fileUpload.forEach((fileUpload) => fileUpload.startUpload());
      } else {
        this.$emit("taskfileupload-success");
      }
    },

    resetMediaContent(media) {
      if (media.type === "video") {
        media.content = [{ originalFileName: "" }];
      } else {
        media.content = [];
      }
    },

    onFileUploadSuccess() {
      const isCompleted = this.$refs.fileUpload.every((fileUpload) => {
        return fileUpload.$refs.uploader.dropzone.files.every(
          (file) => file.status === "success" || file.manuallyAdded
        );
      });
      if (isCompleted || !this.hasFiles) {
        this.hasFiles = false;
        this.$emit("taskfileupload-success");
      }
    },
  },
};
</script>

<template lang="pug">
  .modal__block
    .modal__section
      .modal__sign.sign
        .sign__icon
          micon(name="insert_chart_outlined")
        h3.sign__title Respuestas personalizadas
      article.modal__fields
        .responses(v-for="(response, i) in model.responses")
          .modal__row
            fieldset.modal__field
              label.label
                b Respuesta {{getColorName(response.color)}}
            el-button(type='danger' plain @click="removeResponse(i)") &times;
          .modal__row
            fieldset.modal__field
              label.label * Alerta:
              ValidationProvider(:name="`Alerta Respuesta ${getColorName(response.value)}`", rules="required", v-slot="{ errors }")
                el-select(
                  v-model="response.color"
                  placeholder='Seleccionar nivel Alerta'
                  filterable
                  clearable
                  default-first-option
                )
                  el-option(
                    v-for="colorName in colorNames"
                    :key="colorName.value"
                    :label="colorName.label"
                    :value="colorName.value"
                    :disabled='isUsed(colorName.value)'
                  )
                span.has-error {{ errors[0] }}
            fieldset.modal__field
              label.label * Mensaje:
              ValidationProvider(:name="`Mensaje Respuesta ${getColorName(response.color)}`", rules="required", v-slot="{ errors }")
                el-input(v-model="response.message.text" )
                span.has-error {{ errors[0] }}
            fieldset.modal__field
              label.label Color mensaje:
              el-color-picker(
                placeholder="Seleccione un color de texto"
                type="color"
                clearable
                v-model="response.message.textColor"
                name="Color"
                rows="1"
              )
          .response-media(
            v-for='(media, mediaIndex) in response.media'
          )
            fieldset.modal__field
              label.label
                b Recomendación {{mediaIndex +1}}
          
            .modal__row
              fieldset.modal__field
                label.label * Tipo de Recomendación:
                ValidationProvider(:name="`Tipo Archivo Respuesta ${getColorName(response.color)}`", rules="required", v-slot="{ errors }")
                  el-select(v-model="media.type" v-on:change="resetMediaContent(media)")
                    el-option(label="Imagen" value="image")
                    el-option(label="Audio" value="audio")
                    el-option(label="Video" value="video")
                  span.has-error {{ errors[0] }}
            
              fieldset.modal__field
                label.label * Título:
                ValidationProvider(:name="`Titulo Archivo Respuesta ${getColorName(response.color)}`", rules="required", v-slot="{ errors }")
                  el-input(v-model="media.title")
                  span.has-error {{ errors[0] }}

              fieldset.modal__field
                label.label Descripción:
                el-input(v-model="media.description")

            .modal__row(v-if="media.type === 'image' || media.type === 'audio'")
              fieldset.modal__field
                label.label(v-if="media.type === 'image'") Imagen:
                label.label(v-else) Audio:
                file-upload(
                  ref="fileUpload",
                  :type="media.type",
                  :url="uploadMediaEndpoint",
                  :files="getFiles(media)",
                  :dropzone-options="getDropdownOptions()"
                  @file-added="(file) => onFilesAdded(file, media)",
                  @file-removed="(file) => onFileRemoved(file, media)"
                  @files-removed="onFilesRemoved"
                  @fileupload-success="onFileUploadSuccess"
                  @fileupload-error="onMediaError"
                )

            .modal__row(v-if="media.type === 'video'")
              fieldset.modal__field
                label.label * Link del Video:
                ValidationProvider(name="Link", rules="required|url", v-slot="{ errors }")
                  el-input(v-model="media.content[0].value", :class="{'has-error': errors[0]}")
                  span.has-error {{ errors[0] }}

            .modal__row
              fieldset.modal__field
                small
                 a.remove(@click="removeMedia(response, mediaIndex)") &times; Eliminar recomendación {{mediaIndex +1}}

          .modal__row  
            fieldset.modal__field.media__add
              span.response__error(v-if="response.media.length && showMediaResponseError") Debes completar la recomendación de la respuesta {{ getColorName(response.color) }} para poder agregar una nueva
              a.media__add(@click="addMedia(response)") + Agregar recomendación

        .modal__row.responses__add
          fieldset.modal__field
            span.response__error(v-if="model.responses.length && showResponseError") Debes completar la respuesta {{ getColorName(model.responses[model.responses.length -1].color) }} para poder agregar una nueva
            el-button(@click="addResponse") + Agregar respuestas personalizadas

    
</template>

<style lang="scss">
.responses {
  border-bottom: 2px solid $light-gray;
  margin-bottom: 20px;
}

.remove {
  color: $flamingo;
}

.response__error {
  display: block;
  color: $flamingo;
  margin-bottom: 20px;
}

.media__add {
  padding-bottom: 20px;
}

.link {
  width: 93%;
}
</style>
