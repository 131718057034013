<script>
import model from "@/mixins/model";

export default {
  name: "TaskLinks",

  mixins: [model],

  data() {
    return {
      showLinkError: false,
    };
  },

  methods: {
    addLink() {
      this.showLinkError = false;
      const lastLink = this.model.links[this.model.links.length - 1];
      if (lastLink && (!lastLink.name || !lastLink.url)) {
        this.showLinkError = true;
        return;
      }
      this.model.links.push({
        name: "",
        url: "",
      });
    },

    removeLink(index) {
      this.showLinkError = false;
      this.model.links.splice(index, 1);
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="link")
      h3.sign__title Links
    article.modal__fields
      .links(v-for="(link, i) in model.links")
        .modal__row
          fieldset.modal__field
            label.label Link {{ i + 1 }}:
        .modal__row
          fieldset.modal__field
            label.label Título:
            ValidationProvider(:name="`Titulo link ${i + 1}`", rules="required", v-slot="{ errors }")
              el-input(v-model="link.name")
              span.has-error {{ errors[0] }}
          fieldset.modal__field
            label.label URL:
            ValidationProvider(:name="`URL link ${i + 1}`", rules="required|url", v-slot="{ errors }")
              el-input(type="url", v-model="link.url" placeholder='http://www.example.com')
              span.has-error {{ errors[0] }}
        .modal__row
          fieldset.modal__field
            small
              a(@click="removeLink(i)") &times; Eliminar Link {{ i + 1 }}

      .modal__row.links__add
        fieldset.modal__field
          span.link-error(v-if="model.links && showLinkError") Debes completar el link {{ model.links.length }} para poder agregar uno nuevo
          el-button(@click="addLink") + Agregar Link
</template>

<style lang="scss">
.links {
  padding: 20px 0;
  border-bottom: 1px solid $light-gray;
}

.links__add {
  margin: 20px 0;
}

.link-error {
  display: block;
  color: $flamingo;
  margin-left: 10px;
}

.link {
  width: 93%;
}
</style>
